var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('div',{staticClass:"dashboard__container"},[(_vm.user)?_c('div',{staticClass:"dashboard__container--body"},[(_vm.workerPayHistory && _vm.workerPayHistory.items && _vm.workerPayHistory.items.length >= 1)?_c('div',{staticClass:"mb-5 w100"},[_c('h4',{staticClass:"mb-2"},[_vm._v("Pay History")]),_c('vue-good-table',{attrs:{"columns":_vm.columnsPayHistory,"rows":_vm.workerPayHistory.items,"styleClass":"vgt-table condensed","search-options":{
          enabled: false,
          placeholder: 'Search this table',
        },"pagination-options":{
          enabled: true,
          mode: 'records',
          perPage: 25,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'payableNotes')?_c('span',[(props.row.payableNotes)?_c('span',[_vm._v(_vm._s(props.row.payableNotes[0]))]):_vm._e()]):(props.column.field == 'deposits')?_c('span',[(props.row.deposits && props.row.deposits[0])?_c('span',[_vm._v(_vm._s(props.row.deposits[0].destinationLabel))]):_vm._e()]):(props.column.field == 'depositsAmount')?_c('span',[(props.row.deposits)?_c('span',[_vm._v(_vm._s(props.row.deposits[0].amount.amount))]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,3437208266)})],1):_vm._e(),(_vm.employeePayHistory && _vm.employeePayHistory.items && _vm.employeePayHistory.items.length >= 1)?_c('div',{staticClass:"mb-5 w100"},[_c('h4',{staticClass:"mb-2"},[_vm._v("Pay History Jump Employee Services LLC")]),_c('vue-good-table',{attrs:{"columns":_vm.columnsPayHistory,"rows":_vm.employeePayHistory.items,"styleClass":"vgt-table condensed","search-options":{
          enabled: false,
          placeholder: 'Search this table',
        },"pagination-options":{
          enabled: true,
          mode: 'records',
          perPage: 25,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'payableNotes')?_c('span',[(props.row.payableNotes)?_c('span',[_vm._v(_vm._s(props.row.payableNotes[0]))]):_vm._e()]):(props.column.field == 'deposits')?_c('span',[(props.row.deposits && props.row.deposits[0])?_c('span',[_vm._v(_vm._s(props.row.deposits[0].destinationLabel))]):_vm._e()]):(props.column.field == 'depositsAmount')?_c('span',[(props.row.deposits && props.row.deposits[0])?_c('span',[_vm._v(_vm._s(props.row.deposits[0].amount.amount))]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,2983377659)})],1):_vm._e()]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }